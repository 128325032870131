import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GalleryScreen from "../screens/gallery"

const GalleryPage = ({ data }) => (
  <Layout>
    <SEO title="Gallery" />
    <GalleryScreen
      data={data.allGalleries.edges[0].node}
    />
  </Layout>
)

export default GalleryPage

export const query = graphql`
  query AllGalleries {
    allGalleries {
      edges {
        node {
          images {
            url
          }
        }
      }
    }
  }
`
